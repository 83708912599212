/* web  header */

.jr-view-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 60px;
    background-color: #fff;
    border-bottom: 0.2px solid rgba(144, 145, 156, 0.2);
    position: sticky;
    top: 0px;
    z-index: 999;
}

.view-header {
    padding: 20px;
    display: flex;
    justify-content: space-around;
}

.jr-view-header .web-logo .logo {
    width: 150px;
}

.jr-view-header .web-links img {
    width: 17px;
    margin-right: 10px;
}

.jr-view-header .web-links a {
    padding: 10px;
    font-size: 13.5px;
    color: #8da0b0;
    font-family: 'DM Sans' !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
}

.jr-view-header .web-auth a {
    padding: 10px;
    font-size: 13.5px;
    color: #5C7181;
    font-family: 'DM Sans' !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
}

.jr-view-header .web-links .active {
    background-color: #f5f5f5;
    color: #000000;
    border-radius: 6px;
    font-weight: 500 !important;
}

.jr-view-header .black-btn {
    background-color: #111013;
    padding: 5px 12px;
    gap: 6px;
    color: #fff;
    border-radius: 5px;
}

.jr-view-header .black-btn:hover {
    background-color: cadetblue;
}

/*  Web Header Start */

.mobile-view-only h1 {
    font-size: 12px;
    font-size: 13.5px;
    color: #5C7181;
    font-family: 'DM Sans' !important;
    font-style: normal;
    font-weight: 600;
    line-height: 0px;
    letter-spacing: 0.01em;
    margin-top: 8px;
    margin-left: 10px;
    cursor: pointer;
}

.dra ul li {
    font-family: 'DM Sans' !important;
    font-style: normal;
    font-weight: 600;
}

.avatar-header {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.logout {
    width: 20px;
    margin-right: 5px;
    margin-top: 2px;
    cursor: pointer;
}

.web-auth {
    font-size: 13.5px;
    color: #5C7181;
    font-family: 'DM Sans' !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.01em;
}

.web-auth span {
    cursor: pointer;
}

.warning-pop-up .ant-modal-header,
.warning-pop-up .ant-modal-footer {
    display: none !important;
}

/* .warning-pop-up .ant-modal-body {
    height: 62vh;
} */

.warning-pop-up .pop-table {
    height: 60vh;
}

.active h1 {
    color: red;
    text-align: center;
}

.alert h1 {
    text-align: center;
}

.pop-table {
    margin: 0px !important;
}

@media only screen and (max-width: 600px) {
    .jr-view-header .web-links a {
        display: none;
    }

    .jr-view-header {
        position: relative !important;
        padding: 20px 10px;
    }
}

@media only screen and (min-width: 600px) {
    .jr-view-header .mobile-view-only {
        display: none;
    }
}

@media (min-width:801px) {

    /* tablet, landscape iPad, lo-res laptops ands desktops */
    .jr-view-header .mobile-view-only {
        display: none;
    }
}

@media (min-width:1025px) {

    /* big landscape tablets, laptops, and desktops */
    .jr-view-header .mobile-view-only {
        display: none;
    }
}

@media (min-width:1281px) {

    /* hi-res laptops and desktops */
    .jr-view-header .mobile-view-only {
        display: none;
    }
}

/* web  header */